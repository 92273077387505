import React from "react";
import { graphql } from "gatsby";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';

import { 
    ChallengeGrid,
    ChallengeTable,
    ErrorUI,
    getChallengeStatus,
    Layout,
    Sidebar
} from "../components";

const ChallengeResults = ({
    data,
    pageContext: { nextPagePath, previousPagePath },
}) => {

    const challenges = data.allWpChallenge.nodes;
    const hasChallenges = challenges.length > 0;
    const formattedChallenges = formatChallenges(challenges);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Layout 
            title="All Challenges"
        >
            {hasChallenges ? (
                <Box
                    sx={
                        {
                            py: 2
                        }
                    }
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            lg={true}
                        >
                            <Box>
                                <Stack
                                    direction="column"
                                    spacing={1}
                                >
                                    <Typography
                                        color="text.headline"
                                        component="h2"
                                        sx={
                                            {
                                                backgroundClip: 'text',
                                                backgroundColor: 'rgba(255,240,0,1)',
                                                backgroundImage: 'linear-gradient(to bottom, rgba(255,240,0,0) 30%, rgba(255,83,16,1) 100%)',
                                                fontSize: {
                                                    lg: 32,
                                                    xs: 26
                                                },
                                                fontWeight: 'bold',
                                                letterSpacing: '-0.06em',
                                                textFillColor: 'transparent',
                                                textShadow: '0px 0px 10px rgba(0, 0, 0, 0.41)',
                                                textTransform: 'uppercase'
                                            }
                                        }
                                        variant="h2"
                                    >
                                        {"All Challenges"}
                                    </Typography>
                                    <Typography
                                        color="text.secondary"
                                        component="div"
                                        sx={
                                            {
                                                fontSize: {
                                                    lg: 18,
                                                    xs: 16
                                                },
                                            }
                                        }
                                    >
                                        {"Check out the challenges we had and the upcoming ones"}
                                    </Typography>
                                </Stack>
                            </Box>
                            <Box
                                sx={
                                    {
                                        mt: 4
                                    }
                                }
                            >
                                {isSmallScreen ? (
                                    <ChallengeGrid 
                                        challenges={formattedChallenges} 
                                        showResults={true}
                                    />
                                ) : (
                                    <ChallengeTable
                                        challenges={formattedChallenges} 
                                        showResults={true}
                                    />
                                )}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            lg="auto"
                        >
                            <Sidebar />
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <ErrorUI 
                    content={"There are no challenges at the moment. Please try again."}
                    title={"No Challenges"}
                />
            )}
        </Layout>
    )
}
export default ChallengeResults;

function formatChallenges(challenges) {
    
    let formattedChallenges = challenges.map((challenge) => {
        let status = getChallengeStatus(challenge);
        return { ...challenge, status: status };
    });

    let groupedChallenges = groupBy(formattedChallenges, challenge => challenge.status);

    let running = groupedChallenges.get('running');
    let waiting = groupedChallenges.get('waiting');
    let over = groupedChallenges.get('over');

    let _challenges = [];
    if ( running !== undefined ) {
        _challenges = _challenges.concat(running);
    }
    if ( waiting !== undefined ) {
        _challenges = _challenges.concat(waiting);
    }
    if ( over !== undefined ) {
        _challenges = _challenges.concat(over);
    }

    return _challenges;
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export const pageQuery = graphql`
    query ChallengeResults {
        allWpChallenge(
            sort: { fields: [date], order: DESC }
            limit: 100
            skip: 0
        ) {
            nodes {
                id
                databaseId
                title
                featuredImage {
                    node {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    height: 400
                                    placeholder: TRACED_SVG
                                )
                            }
                        }
                    }
                }
                slug
                questionVideo
                answerVideo
                startDatetime
                endDatetime
                rewardImage {
                    altText
                    localFile {
                        icon: childImageSharp {
                            gatsbyImageData(height: 32)
                        }
                        small: childImageSharp {
                            gatsbyImageData(height: 60)
                        }
                        medium: childImageSharp {
                            gatsbyImageData(width: 200)
                        }
                    }
                }
                rewardName
                rewardSpots
                questionFormFields
                betWindows
                bannerChatBottomDestination
                bannerChatBottomImage {
                    altText
                    localFile {
                        small: childImageSharp {
                            gatsbyImageData(height: 60)
                        }
                        medium: childImageSharp {
                            gatsbyImageData(width: 400)
                        }
                    }
                }
                bannerChatTopDestination
                bannerChatTopImage {
                    altText
                    localFile {
                        small: childImageSharp {
                            gatsbyImageData(height: 60)
                        }
                        medium: childImageSharp {
                            gatsbyImageData(width: 400)
                        }
                    }
                }
                adPreRollId
                adPreRollHeadline
                adPreRollDisplayUrl
                adPreRollFinalUrl
                adEndRollId
                adEndRollHeadline
                adEndRollDisplayUrl
                adEndRollFinalUrl
                adReplayId
                adReplayHeadline
                adReplayDisplayUrl
                adReplayFinalUrl
            }
        }
    }
`
